import {
  ACCOUNTS_DOCUMENTS_PATH,
  ACCOUNTS_DOCUMENT_DETAILS_PATH,
  ACCOUNTS_HCF_DOCUMENT_BY_ID_AND_NAME_PATH,
} from "@src/appV2/Accounts/Documents/paths";
import { ACCOUNTS_PATH } from "@src/appV2/Accounts/paths";

import { GlobalRouterPath } from "./globalRoute";

export const AppV2AccountRoutes = {
  DOCUMENTS: `${GlobalRouterPath.APP_V2_HOME}/${ACCOUNTS_PATH}/${ACCOUNTS_DOCUMENTS_PATH}`,
  HCF_DOCUMENT_BY_ID_AND_NAME: `${GlobalRouterPath.APP_V2_HOME}/${ACCOUNTS_PATH}/${ACCOUNTS_HCF_DOCUMENT_BY_ID_AND_NAME_PATH}`,
  DOCUMENTS_DETAILS: `${GlobalRouterPath.APP_V2_HOME}/${ACCOUNTS_PATH}/${ACCOUNTS_DOCUMENT_DETAILS_PATH}`,
};
