import { AppLoadingPage } from "@src/appV2/lib";
import { ReactElement } from "react";
import { Redirect } from "react-router-dom";

import { GlobalRouterPath } from "./constant/globalRoute";

interface PublicPageProps {
  authStateHasLoaded: boolean;
  isAuthorized: boolean;
  children: ReactElement;
}

export function PublicPage(props: PublicPageProps): ReactElement {
  const { isAuthorized, authStateHasLoaded, children } = props;
  if (!authStateHasLoaded) {
    return <AppLoadingPage />;
  }
  if (isAuthorized) {
    return <Redirect to={GlobalRouterPath.HOME} />;
  }
  return children;
}
