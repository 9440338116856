import { connect } from "react-redux";

import { AppRouter } from "./appRouter";
import { Store } from "../store/store.model";

const mapStateToProps = (state: Store) => ({
  authStateHasLoaded: state.session.authStateHasLoaded,
  isAuthorized: state.session.isAuthorized,
  isVerifying: state.session.isVerifying,
  isLoggingOut: state.session.isLoggingOut,
  userId: state.session.userId,
});

const AppRoutes = connect(mapStateToProps)(AppRouter);

export { AppRoutes };
