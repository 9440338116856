import "./globalBanners.scss";
import { CameraSource } from "@capacitor/camera";
import { isPlatform } from "@ionic/core";
import { IonAlert, IonToast } from "@ionic/react";
import { OpenNativeSettings } from "@ionic-native/open-native-settings";
import { useAppSelector } from "@src/app/store";
import { UnverifiedShiftModals } from "@src/app/unverifiedShifts/unverifiedShiftModals";
import { ActionType } from "@store/session";
import { capitalize } from "lodash";
import { FC } from "react";
import { useDispatch } from "react-redux";

export const GOTO_SETTINGS = "Go to settings";
const CLOSE = "Close";
export const getNeedAccessToMessage = (needAccessTo) =>
  `Please give access to ${capitalize(needAccessTo)} in settings.`;
const openSettings = () => {
  if (isPlatform("capacitor")) {
    OpenNativeSettings.open("application_details");
  }
};

const TOAST_DURATION = 5000;
export const GlobalBanners: FC = () => {
  return (
    <>
      <CantAddFriendAlert />
      <NeedAccessToToast />
      <UnverifiedShiftModals />
    </>
  );
};

function CantAddFriendAlert() {
  const dispatch = useDispatch();
  const { cantAddFriendPopup } = useAppSelector(
    (state) => state?.session?.popups ?? { cantAddFriendPopup: undefined }
  );
  const onDeepLinkCantAddFriendAlertDismiss = () => {
    dispatch({
      type: ActionType.HIDE_DEEP_LINK_WWF_CANNOT_ADD_FRIEND_ALERT,
    });
  };
  return (
    <IonAlert
      isOpen={!!cantAddFriendPopup?.visible}
      buttons={[
        {
          text: "OK",
          handler: onDeepLinkCantAddFriendAlertDismiss,
        },
      ]}
      backdropDismiss={false}
      header={cantAddFriendPopup?.header}
      message={cantAddFriendPopup?.message}
    />
  );
}

function NeedAccessToToast() {
  const dispatch = useDispatch();
  const { userDeniedAccessTo } = useAppSelector(
    (state) => state?.session?.popups ?? { userDeniedAccessTo: undefined }
  );
  const { needAccessTo = CameraSource.Camera } = userDeniedAccessTo ?? {};
  const message = getNeedAccessToMessage(needAccessTo);

  return (
    <IonToast
      duration={TOAST_DURATION}
      isOpen={!!userDeniedAccessTo?.visible}
      message={message}
      position="top"
      color={"warning"}
      cssClass={"need-access-toast"}
      onDidDismiss={() => dispatch({ type: ActionType.HIDE_NEED_ACCESS_TO_TOAST })}
      buttons={[
        {
          text: GOTO_SETTINGS,
          handler: openSettings,
        },
        {
          text: CLOSE,
          role: "cancel",
        },
      ]}
    />
  );
}
