import { isDefined } from "@clipboard-health/util-ts";
import { useLocation, useRouteMatch } from "react-router-dom";

import { getRouterPathInfo } from "../constant";

/**
 * @deprecated This function is deprecated and will be removed when we migrate review summary dialog to a page.
 * We currently utilize this function to categorize the review summary dialog as a page within UX Cam.
 */
export const useActiveScreenNameDeprecated = (): string => {
  const location = useLocation();
  const match = useRouteMatch(location.pathname);
  const pathInfo = isDefined(match) ? getRouterPathInfo(match.path) : undefined;
  return isDefined(pathInfo) ? pathInfo.name : location.pathname;
};
