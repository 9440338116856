import { FeatureFlagVariants, useCbhFlags } from "@src/appV2/FeatureFlags";
// eslint-disable-next-line no-restricted-imports
import { CbhFeatureFlag } from "@src/appV2/FeatureFlags";
// eslint-disable-next-line no-restricted-imports
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";

export type TFeatureFlagWithValue = {
  key: CbhFeatureFlag;
  value: unknown;
};

export interface useRouteIsAllowedProps {
  conditionalFlagValues: TFeatureFlagWithValue[];
  conditional: "AND" | "OR";
  route: string;
}

function isRouteAllowed(
  conditionalFlagValues: TFeatureFlagWithValue[],
  conditional: useRouteIsAllowedProps["conditional"],
  ldFlags: FeatureFlagVariants
): boolean {
  if (conditional === "AND") {
    return conditionalFlagValues.every((flag) => ldFlags[flag.key] === flag.value);
  }

  return conditionalFlagValues.some((flag) => ldFlags[flag.key] === flag.value);
}

export enum RoutePermissionState {
  PENDING,
  ALLOWED,
  DENIED,
}

/**
 * @deprecated
 * This is simply unnecessarily overly complicated logic for flags.
 * Just do the logic in code!
 */
export const useDeprecatedDoNotUseRouteIsAllowed = ({
  conditionalFlagValues,
  conditional = "AND",
  route,
}: useRouteIsAllowedProps) => {
  const ldFlags = useCbhFlags();
  /**
   * @description using useRouteMatch because current implementation of matching route doesn't work with
   * url params.
   */
  const routeMatch = useRouteMatch({ path: route, exact: true });
  const ldClient = useLDClient();
  const [isLdContextLoaded, setIsLdContextLoaded] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      // FIXME: Refactor logic to not depend on `getContext`
      if (!ldClient?.getContext().anonymous) {
        clearInterval(interval);
        setIsLdContextLoaded(true);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [ldClient]);

  function getRoutePermission(): RoutePermissionState {
    if (!isLdContextLoaded || !routeMatch?.isExact) {
      return RoutePermissionState.PENDING;
    }
    return isRouteAllowed(conditionalFlagValues, conditional, ldFlags)
      ? RoutePermissionState.ALLOWED
      : RoutePermissionState.DENIED;
  }

  return getRoutePermission();
};
