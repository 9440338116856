import {
  ReferAndEarnRouterPath,
  ReferAndEarnRouterPathInfo,
} from "@src/appV2/Accounts/ReferAndEarn/paths";
import {
  WorkplaceReferralRouterPath,
  WorkplaceReferralRouterPathInfo,
} from "@src/appV2/Accounts/WorkplaceReferrals/paths";
import { RootPaths } from "@src/appV2/App/paths";
import {
  WorkplaceReviewsRouterPath,
  WorkplaceReviewsRouterPathInfo,
} from "@src/appV2/Reviews/paths";
import { findLastKey } from "lodash";
import { matchPath } from "react-router";

import { GlobalRouterPathInfo } from "./globalRoute";
import { RoutePathInfo } from "./interface";
import { OnboardingRouterPath, OnboardingRouterPathInfo } from "./onboardingRoute";
import { TabRouterPath, TabRouterPathInfo } from "./tabRoute";
import { WelcomeRouterPath, WelcomeRouterPathInfo } from "./welcomeRoute";

export const RouterPath = {
  ...RootPaths,
  ...WelcomeRouterPath,
  ...OnboardingRouterPath,
  ...TabRouterPath,
  ...WorkplaceReviewsRouterPath,
  ...WorkplaceReferralRouterPath,
  ...ReferAndEarnRouterPath,
};

/**
 * @deprecated
 * This is no longer being maintained.
 * This is only used for logging and for one deprecated hook.
 * We will no longer be maintaining names for routes
 * The routes are the definitive truth, and it is more useful to know the exact route in logs.
 */
const RouterPathInfo = {
  ...GlobalRouterPathInfo,
  ...WelcomeRouterPathInfo,
  ...OnboardingRouterPathInfo,
  ...TabRouterPathInfo,
  ...WorkplaceReviewsRouterPathInfo,
  ...WorkplaceReferralRouterPathInfo,
  ...ReferAndEarnRouterPathInfo,
};

/**
 * @deprecated
 * This is no longer being maintained.
 * This is only used for logging and for one deprecated hook.
 * We will no longer be maintaining names for routes
 * The routes are the definitive truth, and it is more useful to know the exact route in logs.
 */
export const getRouterPathInfo = (path: string): RoutePathInfo | undefined => {
  const routerKey = findLastKey(RouterPath, (route) =>
    matchPath(path, {
      path: route,
      exact: true,
      strict: false,
    })
      ? true
      : false
  );
  if (!routerKey) {
    return undefined;
  }
  return RouterPathInfo[routerKey];
};
