import { AppLoadingPage } from "@src/appV2/lib";
import { FC } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";

import { GlobalRouterPath } from "./constant/globalRoute";
import { PrivateRouteProps } from "./model";

/**
 * @deprecated FIXME: This component is poorly written.
 * Refactor to standard declarative components.
 * https://linear.app/clipboardhealth/issue/FEF-156/rework-publicroute-and-privateroute
 */
export const PrivateRoute: FC<PrivateRouteProps> = ({
  isAuthorized,
  authStateHasLoaded,
  component,
  ...rest
}) => {
  if (!authStateHasLoaded) {
    return <AppLoadingPage />;
  }
  const renderRoute = (props: RouteComponentProps) => {
    /**
     * FIXME - See FEF-156
     * This code should work using `useLocation`. Note that there are race conditions
     * concerns here. `location` is immutable. If other redirects or effects update location,
     * this code may or may not get those changes.
     * This is fragile, and we need to rethink this.
     * Note also - I am pretty sure this has never worked. The intention is to carry the current route
     * through auth state and - upon authentication - redirect to the original route
     * This could never work for the email flow, and doesn't currently work for the OTP auth flow.
     * Example:
     * https://www.loom.com/share/8d595cf0186246e1bce8ec5ec4816327
     */
    const state = {
      from: props.location,
    };

    if (!isAuthorized) {
      return <Redirect to={{ pathname: GlobalRouterPath.WELCOME, state }} />;
    }
    const Component = component!;
    return <Component {...props} />;
  };

  return <Route {...rest} render={renderRoute} />;
};
