import { GlobalRouterPath } from "./globalRoute";
import { RoutePathInfo } from "./interface";

const WelcomeRouterPath = {
  LANDING_PAGE: GlobalRouterPath.WELCOME,
  LOGIN: `${GlobalRouterPath.WELCOME}/login`,
  /**
   * When using log in via email, the API/Firebase's email link points to
   * LOGIN_VERIFY_LINK. This is not part of /v2 auth refactoring.
   */
  LOGIN_VERIFY_LINK: `${GlobalRouterPath.WELCOME}/login/linkVerify`,
  /**
   * This route is used for auto-login links which are generated via HCF Admin App
   */
  LOGIN_EMAIL_VERIFY: `${GlobalRouterPath.WELCOME}/login/emailVerify`,
};

const WelcomeRouterPathInfo: Record<keyof typeof WelcomeRouterPath, RoutePathInfo> = {
  LANDING_PAGE: {
    name: "Landing Page",
  },
  LOGIN: {
    name: "Login",
  },
  LOGIN_VERIFY_LINK: {
    name: "Login Link Verification",
  },
  LOGIN_EMAIL_VERIFY: {
    name: "Login Email Verification",
  },
};

export { WelcomeRouterPath, WelcomeRouterPathInfo };
