import { InlineLoader } from "@src/lib/deprecatedCode";
import { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

import {
  RoutePermissionState,
  useDeprecatedDoNotUseRouteIsAllowed,
  useRouteIsAllowedProps,
} from "./useRouteIsAllowed";
import { GlobalRouterPath } from "../constant/globalRoute";

interface LaunchDarklyFeatureFlagRouterGuardProps extends useRouteIsAllowedProps {
  redirectTo?: string;
  children?: JSX.Element;
}

function GoBack() {
  const [redirecting, setRedirecting] = useState(false);
  const { goBack } = useHistory();

  useEffect(() => {
    if (!redirecting) {
      goBack();
      setRedirecting(true);
    }
  }, [goBack, redirecting]);

  return <></>;
}

/**
 * @deprecated
 * This is simply unnecessarily overly complicated logic for flags.
 * Just do the logic in code!
 */
export const DeprecatedDoNotUseLaunchDarklyFeatureFlagRouteGuard = ({
  redirectTo = GlobalRouterPath.WELCOME,
  children,
  ...props
}: LaunchDarklyFeatureFlagRouterGuardProps) => {
  const routePermission = useDeprecatedDoNotUseRouteIsAllowed(props);
  const { action } = useHistory();

  if (routePermission === RoutePermissionState.PENDING) {
    return (
      <div className="page-loader" data-testid="loader">
        <InlineLoader loading={true} />
      </div>
    );
  }

  if (routePermission === RoutePermissionState.DENIED) {
    if (action === "POP") {
      return <GoBack />;
    } else {
      return <Redirect to={redirectTo} push={false} />;
    }
  }

  return <>{children}</>;
};
