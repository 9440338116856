import { Worker } from "@src/appV2/Worker/api/types";
import { Agent } from "@src/lib/interface";

export const isAgentInSignupProcess = (agent: Agent | Worker): boolean => {
  return Boolean(
    !agent?.active &&
      agent?.onboardingFlags?.isSignupInitiated &&
      !agent?.onboardingFlags?.isSignupCompleted
  );
};
