import { RootPaths } from "@src/appV2/App/paths";

import { RoutePathInfo } from "./interface";

/**
 * @deprecated
 * Use `RootPaths` instead.
 */
export const GlobalRouterPath = RootPaths;

/**
 * @deprecated
 * This is no longer being maintained.
 * This is only used for logging and for one deprecated hook.
 * We will no longer be maintaining names for routes
 * The routes are the definitive truth, and it is more useful to know the exact route in logs.
 */
export const GlobalRouterPathInfo: Record<keyof typeof GlobalRouterPath, RoutePathInfo> = {
  NO_USER_PAGE: {
    name: "No User Page",
  },
  HOME: {
    name: "",
  },
  WELCOME: {
    name: "",
  },
  LINK: {
    name: "Smart Link Page",
  },
  APP_V2: {
    name: "App V2",
  },
  APP_V2_HOME: {
    name: "App V2 Home",
  },
};
